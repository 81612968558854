import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Animate from "components/animate/animate";
import PasswordField from "components/password-field";
import { AutoComplete_ZipCode } from "services/Profile";
import { useHistory } from "react-router-dom";
import { AuthConfigContext } from "context/AuthConfigProvider";
import Form from "@rjsf/core";
import ErrorTop from "components/error/error-top";
import { Register_Ecommerce_Member } from "services/Ecommerce";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";
import "./style.scss";

const formSchema = {
  "type": "object",
  "required": [
    "family_name",
    "name",
    "family_name_furigana",
    "name_furigana",
    "email",
    "password",
    "zipcode",
    "prefCode",
    "city",
    "street",
    "phone",
    "gender",
    "birthdate",
    "receive_dm",
    "receive_letter",
    "favorite_store",
    "agreement"
  ],
  "properties": {
    "family_name": {
      "title": "姓",
      "type": "string",
      "maxLength": 45
    },
    "name": {
      "title": "名",
      "type": "string",
      "maxLength": 45
    },
    "family_name_furigana": {
      "title": "姓(カナ)",
      "type": "string",
      "maxLength": 45
    },
    "name_furigana": {
      "title": "名(カナ)",
      "type": "string",
      "maxLength": 45
    },
    "email": {
      "title": "メールアドレス",
      "type": "string",
      "format": "email",
      "maxLength": 45
    },
    "password": {
      "title": "パスワード",
      "type": "string",
      "minLength": 8,
      "maxLength": 16,
      "pattern": "^(?!.*[,:<>[\\]\"])[a-zA-Z0-9!@#$%^&*()_+{}|;'.?/\\\\~-]+$",
    },
    "zipcode": {
      "title": "郵便番号（ハイフン無し）",
      "type": "string",
      "format": "phone-jp"
    },
    "prefCode": {
      "title": "都道府県",
      "type": "string",
      "anyOf": [
        {
          "type": "string",
          "title": "北海道",
          "enum": ["0101"]
        },
        {
          "type": "string",
          "title": "青森県",
          "enum": ["0202"]
        },
        {
          "type": "string",
          "title": "岩手県",
          "enum": ["0203"]
        },
        {
          "type": "string",
          "title": "宮城県",
          "enum": ["0204"]
        },
        {
          "type": "string",
          "title": "秋田県",
          "enum": ["0205"]
        },
        {
          "type": "string",
          "title": "山形県",
          "enum": ["0206"]
        },
        {
          "type": "string",
          "title": "福島県",
          "enum": ["0207"]
        },
        {
          "type": "string",
          "title": "茨城県",
          "enum": ["0308"]
        },
        {
          "type": "string",
          "title": "栃木県",
          "enum": ["0309"]
        },
        {
          "type": "string",
          "title": "群馬県",
          "enum": ["0310"]
        },
        {
          "type": "string",
          "title": "埼玉県",
          "enum": ["0311"]
        },
        {
          "type": "string",
          "title": "千葉県",
          "enum": ["0312"]
        },
        {
          "type": "string",
          "title": "東京都",
          "enum": ["0313"]
        },
        {
          "type": "string",
          "title": "神奈川県",
          "enum": ["0314"]
        },
        {
          "type": "string",
          "title": "新潟県",
          "enum": ["0415"]
        },
        {
          "type": "string",
          "title": "富山県",
          "enum": ["0416"]
        },
        {
          "type": "string",
          "title": "石川県",
          "enum": ["0417"]
        },
        {
          "type": "string",
          "title": "福井県",
          "enum": ["0418"]
        },
        {
          "type": "string",
          "title": "山梨県",
          "enum": ["0419"]
        },
        {
          "type": "string",
          "title": "長野県",
          "enum": ["0420"]
        },
        {
          "type": "string",
          "title": "岐阜県",
          "enum": ["0421"]
        },
        {
          "type": "string",
          "title": "静岡県",
          "enum": ["0422"]
        },
        {
          "type": "string",
          "title": "愛知県",
          "enum": ["0423"]
        },
        {
          "type": "string",
          "title": "三重県",
          "enum": ["0424"]
        },
        {
          "type": "string",
          "title": "滋賀県",
          "enum": ["0525"]
        },
        {
          "type": "string",
          "title": "京都府",
          "enum": ["0526"]
        },
        {
          "type": "string",
          "title": "大阪府",
          "enum": ["0527"]
        },
        {
          "type": "string",
          "title": "兵庫県",
          "enum": ["0528"]
        },
        {
          "type": "string",
          "title": "奈良県",
          "enum": ["0529"]
        },
        {
          "type": "string",
          "title": "和歌山県",
          "enum": ["0530"]
        },
        {
          "type": "string",
          "title": "鳥取県",
          "enum": ["0631"]
        },
        {
          "type": "string",
          "title": "島根県",
          "enum": ["0632"]
        },
        {
          "type": "string",
          "title": "岡山県",
          "enum": ["0633"]
        },
        {
          "type": "string",
          "title": "広島県",
          "enum": ["0634"]
        },
        {
          "type": "string",
          "title": "山口県",
          "enum": ["0635"]
        },
        {
          "type": "string",
          "title": "徳島県",
          "enum": ["0736"]
        },
        {
          "type": "string",
          "title": "香川県",
          "enum": ["0737"]
        },
        {
          "type": "string",
          "title": "愛媛県",
          "enum": ["0738"]
        },
        {
          "type": "string",
          "title": "高知県",
          "enum": ["0739"]
        },
        {
          "type": "string",
          "title": "福岡県",
          "enum": ["0840"]
        },
        {
          "type": "string",
          "title": "佐賀県",
          "enum": ["0841"]
        },
        {
          "type": "string",
          "title": "長崎県",
          "enum": ["0842"]
        },
        {
          "type": "string",
          "title": "熊本県",
          "enum": ["0843"]
        },
        {
          "type": "string",
          "title": "大分県",
          "enum": ["0844"]
        },
        {
          "type": "string",
          "title": "宮崎県",
          "enum": ["0845"]
        },
        {
          "type": "string",
          "title": "鹿児島県",
          "enum": ["0846"]
        },
        {
          "type": "string",
          "title": "沖縄県",
          "enum": ["0847"]
        }
      ]
    },
    "city": {
      "title": "住所１（市区町村）",
      "type": "string",
      "minLength": 2,
      "maxLength": 45
    },
    "street": {
      "title": "住所２（番地）",
      "type": "string",
      "maxLength": 45
    },
    "building": {
      "title": "住所３（建物名・部屋番号）",
      "type": "string",
      "maxLength": 45
    },
    "phone": {
      "title": "電話番号（ハイフン無し）",
      "type": "string",
      "format": "phone-jp",
      "maxLength": 12,
      "minLength": 9
    },
    "gender": {
      "title": "性別",
      "type": ["string", "null"],
      "disabled": false,
      "anyOf": [
        {
          "type": "string",
          "title": "男性",
          "enum": ["male"]
        },
        {
          "type": "string",
          "title": "女性",
          "enum": ["female"]
        },
        {
          "type": "string",
          "title": "選択なし",
          "enum": ["other"]
        }
      ]
    },
    "birthdate": {
      "title": "生年月日",
      "type": "string",
      "format": "date"
    },
    "receive_dm": {
      "title": "メールマガジン",
      "type": "boolean",
      "default": true,
      "oneOf": [
        {
          "title": "受取る",
          "const": true
        },
        {
          "title": "受取らない",
          "const": false
        }
      ]
    },
    "receive_letter": {
      "title": "店舗からのDM",
      "type": "boolean",
      "default": true,
      "oneOf": [
        {
          "title": "受取る",
          "const": true
        },
        {
          "title": "受取らない",
          "const": false
        }
      ]
    },
    "favorite_store": {
      "title": "お気に入り店舗",
      "type": "string",
      "anyOf": [
        {
          "type": "string",
          "title": "三井アウトレットパーク札幌北広島店",
          "enum": ["31005"]
        },
        {
          "type": "string",
          "title": "大丸札幌店",
          "enum": ["40305"]
        },
        {
          "type": "string",
          "title": "うすい百貨店",
          "enum": ["40901"]
        },
        {
          "type": "string",
          "title": "FKDインターパーク店",
          "enum": ["41201"]
        },
        {
          "type": "string",
          "title": "高崎オーパ店",
          "enum": ["31501"]
        },
        {
          "type": "string",
          "title": "三井アウトレットパーク入間店",
          "enum": ["31015"]
        },
        {
          "type": "string",
          "title": "ららぽーと新三郷店",
          "enum": ["31013"]
        },
        {
          "type": "string",
          "title": "浦和パルコ店",
          "enum": ["31102"]
        },
        {
          "type": "string",
          "title": "そごう大宮店",
          "enum": ["40203"]
        },
        {
          "type": "string",
          "title": "モラージュ菖蒲店",
          "enum": ["30702"]
        },
        {
          "type": "string",
          "title": "ららぽーと富士見店",
          "enum": ["31011"]
        },
        {
          "type": "string",
          "title": "そごう千葉店",
          "enum": ["40205"]
        },
        {
          "type": "string",
          "title": "ららぽーとTOKYO-BAY店",
          "enum": ["31002"]
        },
        {
          "type": "string",
          "title": "流山おおたかの森S・C店",
          "enum": ["30901"]
        },
        {
          "type": "string",
          "title": "銀座三越店",
          "enum": ["40103"]
        },
        {
          "type": "string",
          "title": "日本橋三越本店",
          "enum": ["40102"]
        },
        {
          "type": "string",
          "title": "日本橋タカシマヤ店",
          "enum": ["40403"]
        },
        {
          "type": "string",
          "title": "大丸東京店",
          "enum": ["40302"]
        },
        {
          "type": "string",
          "title": "東武百貨店池袋店",
          "enum": ["41301"]
        },
        {
          "type": "string",
          "title": "伊勢丹新宿店",
          "enum": ["40101"]
        },
        {
          "type": "string",
          "title": "新宿タカシマヤ店",
          "enum": ["40401"]
        },
        {
          "type": "string",
          "title": "グランデュオ立川店",
          "enum": ["40601"]
        },
        {
          "type": "string",
          "title": "三井アウトレットパーク多摩南大沢店",
          "enum": ["31004"]
        },
        {
          "type": "string",
          "title": "セレオ国分寺店",
          "enum": ["30804"]
        },
        {
          "type": "string",
          "title": "錦糸町パルコ店",
          "enum": ["31109"]
        },
        {
          "type": "string",
          "title": "セレオ八王子店",
          "enum": ["30805"]
        },
        {
          "type": "string",
          "title": "グランベリーパーク南町田店",
          "enum": ["31601"]
        },
        {
          "type": "string",
          "title": "ららぽーと海老名店",
          "enum": ["31012"]
        },
        {
          "type": "string",
          "title": "東急たまプラーザ店",
          "enum": ["40801"]
        },
        {
          "type": "string",
          "title": "そごう横浜店",
          "enum": ["40202"]
        },
        {
          "type": "string",
          "title": "横浜高島屋店",
          "enum": ["40404"]
        },
        {
          "type": "string",
          "title": "ラゾーナ川崎プラザ店",
          "enum": ["31007"]
        },
        {
          "type": "string",
          "title": "ららぽーと横浜店",
          "enum": ["31001"]
        },
        {
          "type": "string",
          "title": "ららぽーと湘南平塚店",
          "enum": ["31010"]
        },
        {
          "type": "string",
          "title": "新潟伊勢丹店",
          "enum": ["40110"]
        },
        {
          "type": "string",
          "title": "ららぽーと沼津店",
          "enum": ["31016"]
        },
        {
          "type": "string",
          "title": "静岡パルコ店",
          "enum": ["31101"]
        },
        {
          "type": "string",
          "title": "ららぽーと愛知東郷店",
          "enum": ["31017"]
        },
        {
          "type": "string",
          "title": "松坂屋名古屋店",
          "enum": ["40301"]
        },
        {
          "type": "string",
          "title": "JR京都伊勢丹店",
          "enum": ["40111"]
        },
        {
          "type": "string",
          "title": "大丸京都店",
          "enum": ["40307"]
        },
        {
          "type": "string",
          "title": "イオンモール橿原店",
          "enum": ["30306"]
        },
        {
          "type": "string",
          "title": "大阪タカシマヤ店",
          "enum": ["40402"]
        },
        {
          "type": "string",
          "title": "ルクアイーレ店",
          "enum": ["40107"]
        },
        {
          "type": "string",
          "title": "ららぽーとEXPOCITY店",
          "enum": ["31014"]
        },
        {
          "type": "string",
          "title": "京阪モール店",
          "enum": ["41401"]
        },
        {
          "type": "string",
          "title": "大丸梅田店",
          "enum": ["40304"]
        },
        {
          "type": "string",
          "title": "大丸神戸店",
          "enum": ["40306"]
        },
        {
          "type": "string",
          "title": "イオンモール岡山店",
          "enum": ["30304"]
        },
        {
          "type": "string",
          "title": "ゆめタウン広島店",
          "enum": ["30507"]
        },
        {
          "type": "string",
          "title": "ゆめタウン高松店",
          "enum": ["30509"]
        },
        {
          "type": "string",
          "title": "ららぽーと福岡店",
          "enum": ["31018"]
        },
        {
          "type": "string",
          "title": "ゆめタウン久留米店",
          "enum": ["30504"]
        },
        {
          "type": "string",
          "title": "ゆめタウン行橋店",
          "enum": ["30501"]
        },
        {
          "type": "string",
          "title": "天神本店",
          "enum": ["20200"]
        },
        {
          "type": "string",
          "title": "福岡三越店",
          "enum": ["40109"]
        },
        {
          "type": "string",
          "title": "博多阪急店",
          "enum": ["40702"]
        },
        {
          "type": "string",
          "title": "アミュプラザ小倉店",
          "enum": ["30802"]
        },
        {
          "type": "string",
          "title": "イオンモール福岡店",
          "enum": ["30301"]
        },
        {
          "type": "string",
          "title": "大丸福岡天神店",
          "enum": ["40303"]
        },
        {
          "type": "string",
          "title": "モラージュ佐賀店",
          "enum": ["30701"]
        },
        {
          "type": "string",
          "title": "ゆめタウン佐賀店",
          "enum": ["30508"]
        },
        {
          "type": "string",
          "title": "ゆめタウン夢彩都店",
          "enum": ["30502"]
        },
        {
          "type": "string",
          "title": "アミュプラザ長崎店",
          "enum": ["30808"]
        },
        {
          "type": "string",
          "title": "イオン大村店",
          "enum": ["30206"]
        },
        {
          "type": "string",
          "title": "アミュプラザくまもと店",
          "enum": ["30807"]
        },
        {
          "type": "string",
          "title": "イオンモール熊本店",
          "enum": ["30302"]
        },
        {
          "type": "string",
          "title": "ゆめタウン光の森店",
          "enum": ["30505"]
        },
        {
          "type": "string",
          "title": "サクラマチ熊本店",
          "enum": ["30806"]
        },
        {
          "type": "string",
          "title": "アミュプラザおおいた店",
          "enum": ["30803"]
        },
        {
          "type": "string",
          "title": "ゆめタウン別府店",
          "enum": ["30506"]
        },
        {
          "type": "string",
          "title": "パークプレイス大分店",
          "enum": ["30202"]
        },
        {
          "type": "string",
          "title": "イオンモール宮崎店",
          "enum": ["30307"]
        },
        {
          "type": "string",
          "title": "イオン都城店",
          "enum": ["30204"]
        },
        {
          "type": "string",
          "title": "アミュプラザ鹿児島店",
          "enum": ["30801"]
        },
        {
          "type": "string",
          "title": "サンエー那覇メインプレイス店",
          "enum": ["31701"]
        },
        {
          "type": "string",
          "title": "サンエー浦添西海岸パルコシティ店",
          "enum": ["31110"]
        },
        {
          "type": "string",
          "title": "イオン那覇店",
          "enum": ["30401"]
        },
        {
          "type": "string",
          "title": "イオンモール沖縄ライカム店",
          "enum": ["30305"]
        },
      ]
    },
    "marital_status": {
      "title": "未婚/既婚",
      "type": "string",
      "default": "選択なし",
      "enum": [
        "未婚",
        "既婚",
        "選択なし"
      ]
    },
    "anniversary": {
      "title": "結婚記念日",
      "type": "string",
      "format": "date"
    },
    "occupation": {
      "title": "職業",
      "type": "string",
      "default": "",
      "enum": [
        "",
        "会社員",
        "公務員",
        "自営業/個人事業",
        "会社役員",
        "自由業",
        "専業主婦・主夫",
        "学生",
        "アルバイト/パート",
        "無職",
        "その他"
      ]
    },
    "agreement": {
      "type": "boolean",
      "title": "利用規約に同意の上、保存してください。",
      "enum": [ true ],
      "default": true
    }
  }
}

const uiSchemaEcommerce = {
  "ui:order": [
    "family_name",
    "name",
    "family_name_furigana",
    "name_furigana",
    "email",
    "password",
    "zipcode",
    "prefCode",
    "city",
    "street",
    "building",
    "phone",
    "gender",
    "birthdate",
    "receive_dm",
    "receive_letter",
    "favorite_store",
    "marital_status",
    "anniversary",
    "occupation",
    "agreement"
  ],
  "email": {
    "ui:placeholder": "example@gmail.com"
  },
  "password": {
    "ui:field": "PasswordField",
    "ui:help": (
      <div style={{fontSize: '12px'}}>
        <>
          <span>
            ※半角英数字記号の8～16文字で入力してください。
          </span>
          <br/>
          <span>
            ※「”」「,」「:」「&lt;」「&gt;」「[」「]」は利用できません。
          </span>
        </>
      </div>
    )
  },
  "city": {
    "ui:placeholder": "品川区西五反田"
  },
  "street": {
    "ui:placeholder": "7-20-9"
  },
  "building": {
    "ui:placeholder": ""
  },
  "gender": {
    "ui:widget": "radio"
  },
  "receive_dm": {
    "ui:widget": "radio"
  },
  "receive_letter": {
    "ui:widget": "radio"
  },
  "agreement": {
    "ui:help": (
      <div>
        <>
          <a target="_blank" rel="noopener noreferrer" href="/agreement">
            利用規約はこちら
          </a>
        </>
      </div>
    )
  },
  "anniversary": {
    "ui:help": (
      <div>
        <>
        <button class="form-control" type="button" style={{ width:'180px',marginTop:'10px' }} onClick={() => resetAnniversaryValue()}>結婚記念日リセット</button>
        </>
      </div>
    )
  }
}

const resetAnniversaryValue = () => { 
  if(document.getElementById('root_anniversary')){
      document.querySelector('#root_anniversary').value = ""
  }
};
const CreateAccount = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const authConf = useContext(AuthConfigContext);
  const [schema, setSchema] = useState({});
  const [profileDetails] = useContext(ProfileDetailsContext);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uiSchema, setUiSchema] = useState({});

  useEffect(() => {
    const initializeSchema = () => {
      setSchema(formSchema);
      setUiSchema(uiSchemaEcommerce);
    };
    initializeSchema();
  }, [authConf]);

  useEffect(() => {
    const populateFormData = () => {
      const profileIsEmpty = Object.keys(profileDetails).length === 0;
      if(!profileIsEmpty) {
        let convertedBirthDay = undefined;
        if(profileDetails.birthdate) {
          convertedBirthDay = createBirthDateString(profileDetails.birthdate)
        }
        let matchedFormData = {
          family_name: profileDetails.family_name,
          name: profileDetails.name,
          family_name_furigana: profileDetails.family_name_furigana,
          name_furigana: profileDetails.name_furigana,
          email: profileDetails.email,
          prefCode: profileDetails.custom_data?.prefCode,
          city: profileDetails.custom_data?.city,
          street: profileDetails.custom_data?.street,
          building: profileDetails.custom_data?.building,
          phone: profileDetails.phone,
          gender: profileDetails.gender,
          birthdate: convertedBirthDay,
          receive_letter: profileDetails.custom_data?.receive_letter,
          receive_dm: profileDetails.custom_data?.receive_dm,
          favorite_store: profileDetails.custom_data?.favorite_store,
          marital_status: profileDetails.custom_data?.marital_status,
          anniversary: profileDetails.custom_data?.anniversary,
          occupation: profileDetails.custom_data?.occupation,
          agreement: profileDetails.custom_data?.agreement
        }
        if(matchedFormData.receive_letter==null){
          matchedFormData.receive_letter=true;
        }
        if(matchedFormData.receive_dm==null){
          matchedFormData.receive_dm=true;
        }
        if (matchedFormData.birthdate ==null ) {
          matchedFormData.birthdate = '2000-01-01';
        }
        if (matchedFormData.marital_status == null ) {
          matchedFormData.marital_status = '選択なし';
        }
        // empty name if login with social
        if (profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length) {
          matchedFormData.name = ""
        }
        setFormData(matchedFormData)
      }
    };
    populateFormData();
  }, [schema, profileDetails])


  const createBirthDateString = (day) => {
    let bdate = new Date(day)
    const offset = bdate.getTimezoneOffset();
    bdate = new Date(bdate.getTime() - (offset*60*1000));
    return bdate.toISOString().split('T')[0];
  }

  const submit = async (values) => {
    let dataVals = values.formData;

    setFormData(dataVals);

    dataVals.client_id = authConf?.configJson?.client_id

    if(dataVals?.zipcode) {
      dataVals.zipcode = dataVals.zipcode.toString()
    }
    if(document.getElementById('root_anniversary')){
        if(document.querySelector('#root_anniversary').value == ""){
          dataVals.anniversary = ""
        }
    }

    const token = localStorage.getItem("accesstoken") || "";
    const dataPost = await Register_Ecommerce_Member(dataVals, token);
    // console.log('dataPost res:', dataPost);
      if(dataPost?.message === 'Success') {
        // console.log('dataPost success:', dataPost);
        Swal.fire({
          icon: "success",
          title: t("profile_page.create_page.create_success"),
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        setTimeout(() => {
          Swal.close();
          window.location.href = "/home";
        }, 3000);
      } else {
        console.log('error in registerUser:', dataPost)
        setError(true);
        let errorDescription = dataPost?.response?.data?.errors?.error_description
        if(errorDescription) {
          setErrorMessage(errorDescription);
        } else {
          setErrorMessage("アカウント作成ができない。")
        }
      }
  }

  useEffect(() => {
    if (profileDetails.gender) {
      window.location.href = '/';
    }
  }, [profileDetails])

  useEffect(() => {
    // Reset error message after 7 seconds
    setTimeout(() => {
      setError(false)
      setErrorMessage('')
    }, 7000);
  }, [error, errorMessage])

  const customFormats = {
    'phone-jp': /^[0-9]*$/
  };

  function transformErrors(errors) {
    return errors.map(error => {
      if(error.name === "enum") {
        if(error.property === ".agreement") {
          error.message= "利用規約の同意が必要になります"
        } else if (error.property === ".gender"){
          error.message = ""
        } else {
          error.message = "こちらは必須項目です";
        }
      }
      if(error.name === "required") {
        error.message = "こちらは必須項目です";
      }
      if(error.name === "maxLength") {
        if(error.property === ".password") {
          // error.message = `8文字以上${error.params.limit || 16}文字以下を入力してください`
          // error.message = '半角英数字記号の8～16文字で入力してください。'
          error.message = '半角英数字記号の8～16文字で入力してください。「”」「,」「:」「<」「>」「[」「]」は利用できません。'
        } else {
          error.message = `${error.params.limit}文字以上ではありません`
        }
      }
      if(error.name === "minLength" || error.name === "pattern") {
        if (error.property === ".password") {
          // error.message = `半角英数字${error.params.limit || 8}文字以上で入力してください`
          error.message = '半角英数字記号の8～16文字で入力してください。'
        } else {
          error.message = `${error.params.limit}文字以下ではありません`
        }
      }
      if(error.name === "format") {
        if(error.params.format === "phone-jp") {
          error.message = "数字だけでお願いします"
        } else if(error.params.format === "email") {
          error.message = "有効なEメールを使用してください"
        } 
      }
      if(error.property === ".zipcode") {
        error.message = `${error.message}。7桁の郵便番号（ハイフンなし）を入力してください。`
      }
      if(error.message === "should match some schema in anyOf") {
        if(error.property === ".gender") {
          error.message = "こちらは必須項目です"
        } else {
          error.message = "";
        }
      }
      if(error.message === "should be boolean") {
        error.message = "";
      }
      return error;
    });
  };

  function onError (errors) {
    const errorField = document.querySelector('.field-error');
    if (errorField) errorField.scrollIntoView();
  }

  return (
    <Animate>
      <div className="profile-create">
        <div className="card">
        {error && <ErrorTop message={errorMessage} />}
          <div className="card-title">
            <h1>{t("profile_page.create_page.title")}</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            {schema && Object.keys(schema).length > 0 ? (
              <Form
                noHtml5Validate
                // validate={}
                transformErrors={transformErrors}
                onError={onError}
                customFormats={customFormats}
                schema={schema}
                uiSchema={uiSchema}
                fields={{ PasswordField }}
                formData={formData}
                onSubmit={values => submit(values)}
                onChange={AutoComplete_ZipCode}
                >
                <div className="action-area">
                  <div className="back">
                    <button type="button" className="btn btn-back" onClick={() => history.goBack()}>
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </div>
                  <div className="submission">
                    <button
                      type="submit"
                      className="btn btn-save"
                    >
                      {t("profile_page.edit_page.button_submit")}
                    </button>
                  </div>
                </div>
              </Form>
            ) : (
              <>
                <div className="no-schema">
                  <span>{t("common.no_info")}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default CreateAccount;
