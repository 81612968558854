import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Animate from "components/animate/animate";
import "./style.scss";
import { useTranslation } from 'react-i18next';
import UsePointsBtn from "../../../components/points/use-points-btn";
import { Use_Points } from 'services/Points';
import Swal from "sweetalert2";
import GrayBar from 'components/points/gray-bar';
import CancelBtn from 'components/points/cancel-btn';
import Popup from 'components/popup';
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";

const PointsConfirm = ({history}) => {
  const { t } = useTranslation();
  // console.log('state:', history.location.state);
  const [pointsInfo] = useState(history.location?.state);
  const [showPopup, setShowPopup] = useState(false)
  const [profileDetails] = useContext(ProfileDetailsContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [bg, setBg] = useState("#001233");

  const displayPopup = (e) => {
    e.preventDefault()
    setShowPopup(true);
  }

  const submit = async () => {
    console.log(pointsInfo)
    let body = {}
    body.userId = profileDetails?.identities?.find(x => { return x.provider === 'festaria'})?.user_id;
    if (!body.userId) {
      body.userId = profileDetails.festaria_id;
    }
    if (!body.userId) {
      return;
    }
    setSubmitLoading(true)
    setBg("rgb(189 195 208)");
    body.subPoint = pointsInfo.pointsToUse
    const sendUpdate = await Use_Points(body);
    if (sendUpdate) {
      console.log('sendUpdate:', sendUpdate);
      Swal.fire({
        icon: "success",
        title: t("points_page.success"),
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      setTimeout(() => {
        Swal.close();
        window.location.href = "/point";
      }, 3000);
    }
  }

  return (
    <Animate>
      {showPopup &&
        <Popup  
          action={submit}
          closePopup={() => {window.location.href = "/point";}}
          title={t('points_page.popup.title')}
          description={t('points_page.popup.description')}
          disabled={submitLoading}
          bg={bg}
        />
      }
      <div className="points-confirm">
        <GrayBar>
          <Link to="/point/input">
              <button type="button">
                <i className="fas fa-chevron-left"></i>
                {t("points_page.back_to_points_home")}
              </button>
            </Link>
        </GrayBar>
        <div className="main-top">
          <div className="info-amount">
            <div className="info-group">
              <p>{t("points_page.points_amount_text")}</p>
              <h1>{pointsInfo?.pointsTotal} <span style={{fontSize: '14px'}}>pt</span></h1>
            </div>
            <div className="info-group">
              <p>{t("points_page.points_input")}</p>
              <h1>{pointsInfo?.pointsToUse} <span style={{fontSize: '14px'}}>pt</span></h1>
            </div>
          </div>
          <hr>
          </hr>
          <div className="info-sum">
            <p>{t("points_page.points_after_use")}</p>
            <h1>{pointsInfo?.pointsRemaining} <span style={{fontSize: '14px'}}>pt</span></h1>
          </div>
        </div>

        <div className="main-bottom">
          <div className="show-staff">
            {t("points_page.show_to_staff")}
          </div>
          <div className="bottom-actions">
            <UsePointsBtn text={t("points_page.finalize")} isLink={false} callback={displayPopup} />
            <CancelBtn text={"points_page.cancel"} path={"/point/input"} />
          </div>
        </div>
      </div>
    </Animate>
  );
}


export default PointsConfirm;
